import React, { useLayoutEffect, useEffect, useState, useRef } from 'react'

const Ftso_index = () => {

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const script = document.createElement('script');
        const linkrel = document.createElement('link');
        const linkrel2 = document.createElement('link');
        const script2 = document.createElement('script');

        script.src = "/assets/index.28c5c0b7.js";
        script.type = "module"
        script.crossOrigin = true
        script.async = true;

        linkrel.rel = "modulepreload"
        linkrel.href = "/assets/vendor.5d4a8e8f.js"

        linkrel2.rel = "stylesheet"
        linkrel2.href = "/assets/index.f5ea000b.css"


        document.body.appendChild(script);
        document.body.appendChild(linkrel);
        document.body.appendChild(linkrel2);


        script2.src = "/src/assets/js/walletConnect.js";
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(linkrel);
            document.body.removeChild(linkrel2);
            document.body.removeChild(script2);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])



    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div id="app"></div>
            {loading  && <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: '100%',
                height: "100vh",
                background: "#0b0000",
                zIndex: 20,
                display: 'flex',
                alignItems:"center",
                justifyContent:"center"
            }}>
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
            </div>}
        </div>
    )
}

export default Ftso_index