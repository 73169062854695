import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import React from 'react';
import Home from "./pages/home/Home";
import Ftsoflare from "./pages/ftso-flare/Ftsoflare";
import Ftso_index from "./pages/ftso/Ftso-Index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ftso" element={<Ftso_index />} />
        <Route path="/ftso-flare" element={<Ftsoflare />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;